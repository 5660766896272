import React, { useState, useEffect } from 'react';

function Projects() {
  const [projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/api/projects')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setProjects(data);
        setLoading(false);
      })
      .catch(error => {
        setError('Error fetching projects: ' + error.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <div className="text-center mt-20">Loading projects...</div>;
  if (error) return <div className="text-center mt-20 text-red-500">{error}</div>;

  return (
    <div className="container mx-auto mt-20 p-4">
      <h1 className="text-3xl font-bold mb-4">Projects</h1>
      {projects && projects.map(project => (
        <div key={project.id} className="mb-4 p-4 bg-gray-800 rounded">
          <h2 className="text-xl font-semibold">{project.name}</h2>
          <p className="mt-2">{project.description}</p>
          <a href={project.url} className="text-blue-400 hover:underline mt-2 inline-block">View Project</a>
        </div>
      ))}
    </div>
  );
}

export default Projects;