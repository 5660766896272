import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { PlusCircle, Folder, X, GripVertical } from 'lucide-react';
import { toast } from 'react-toastify';
import Login from './Login';  // Import the Login component

function Notes() {
  const [folders, setFolders] = useState([]);
  const [notes, setNotes] = useState([]);
  const [newFolderName, setNewFolderName] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showNewFolderInput, setShowNewFolderInput] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchNotes = useCallback(async () => {
    try {
      const response = await fetch('/api/notes');
      const data = await response.json();
      setFolders(data.folders);
      setNotes(data.notes);
      setIsAuthenticated(data.is_authenticated);
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to fetch notes. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  const handleLogin = async (success) => {
    if (success) {
      setIsAuthenticated(true);
      setShowLoginForm(false);
      await fetchNotes();
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('/api/logout', {
        method: 'POST',
        credentials: 'include',
      });
      const data = await response.json();
      if (data.success) {
        setIsAuthenticated(false);
        await fetchNotes();
      } else {
        console.error('Logout failed:', data.error);
        toast.error('Logout failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred during logout. Please try again.');
    }
  };

  const handleAddNote = () => {
    navigate('/new_note');
  };

  const createFolder = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/create_folder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ folder_name: newFolderName })
      });
      const data = await response.json();
      if (data.success) {
        setFolders([...folders, data.folder]);
        setNewFolderName('');
        setShowNewFolderInput(false);
        toast.success('Folder created successfully');
      } else {
        toast.error('Failed to create folder: ' + data.error);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while creating the folder.');
    }
  };
  
  const deleteFolder = async (folderId) => {
    if (window.confirm('Are you sure you want to delete this folder? All notes will be moved to Uncategorized.')) {
      try {
        const response = await fetch('/api/delete_folder', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ folder_id: folderId })
        });
        const data = await response.json();
        if (data.success) {
          setFolders(folders.filter(folder => folder.id !== folderId));
          setNotes(notes.map(note => 
            note.folder_id === folderId ? {...note, folder_id: null} : note
          ));
          toast.success('Folder deleted successfully');
        } else {
          toast.error('Failed to delete folder: ' + data.error);
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('An error occurred while deleting the folder.');
      }
    }
  };

  const updateNoteFolder = async (noteId, folderId) => {
    try {
      const response = await fetch('/api/update_note_folder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ note_id: noteId, folder_id: folderId })
      });
      const data = await response.json();
      if (!data.success) {
        setNotes(prevNotes => prevNotes.map(note => 
          note.id === noteId ? {...note, folder_id: note.folder_id} : note
        ));
        toast.error('Failed to update note folder: ' + data.error);
      }
    } catch (error) {
      console.error('Error:', error);
      setNotes(prevNotes => prevNotes.map(note => 
        note.id === noteId ? {...note, folder_id: note.folder_id} : note
      ));
      toast.error('An error occurred while updating the note folder.');
    }
  };

  const onDragEnd = (result) => {
    if (!isAuthenticated) return;
  
    const { destination, source, type, draggableId } = result;
  
    if (!destination) {
      return;
    }
  
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
  
    if (type === 'folder') {
      const newFolders = Array.from(folders);
      const [reorderedFolder] = newFolders.splice(source.index, 1);
      newFolders.splice(destination.index, 0, reorderedFolder);
  
      setFolders(newFolders);
  
      // Send the new order to the server
      const folderOrder = newFolders.map(folder => folder.id);
      fetch('/api/update_folder_order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ folder_order: folderOrder })
      })
      .then(response => response.json())
      .then(data => {
        if (!data.success) {
          toast.error('Failed to update folder order: ' + data.error);
          // Revert to the original order if the server update fails
          fetchNotes();
        }
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error('An error occurred while updating folder order.');
        // Revert to the original order if the server update fails
        fetchNotes();
      });
    } else if (type === 'note') {
      const newFolderId = destination.droppableId === 'uncategorized' ? null : parseInt(destination.droppableId);
      const noteId = parseInt(draggableId);
  
      // Optimistic update
      setNotes(prevNotes => prevNotes.map(note => 
        note.id === noteId ? {...note, folder_id: newFolderId} : note
      ));
  
      // Server update
      updateNoteFolder(noteId, newFolderId);
    }
  };

  const renderNotes = (folderNotes, folderId) => {
    return (
      <Droppable droppableId={folderId === null ? 'uncategorized' : folderId.toString()} type="note" isDropDisabled={!isAuthenticated}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={`min-h-[50px] p-2 rounded transition-colors duration-200 ease-in-out ${
              snapshot.isDraggingOver ? 'bg-gray-700' : ''
            }`}
          >
            {folderNotes.map((note, index) => (
              <Draggable key={note.id} draggableId={note.id.toString()} index={index} isDragDisabled={!isAuthenticated}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={`
                      bg-gray-800 p-3 mb-2 rounded shadow-md transition-all duration-200 ease-in-out flex items-center
                      ${snapshot.isDragging ? 'shadow-lg ring-2 ring-blue-500 scale-105' : 'hover:shadow-lg hover:bg-gray-750'}
                      ${!isAuthenticated ? 'cursor-default' : ''}
                    `}
                    style={{
                      ...provided.draggableProps.style,
                      transform: snapshot.isDragging ? `${provided.draggableProps.style.transform} scale(1.05)` : provided.draggableProps.style.transform,
                    }}
                  >
                    <div {...provided.dragHandleProps} className="mr-2 cursor-grab active:cursor-grabbing" title="Drag to move note">
                      <GripVertical size={16} className="text-gray-400 hover:text-gray-200 transition-colors" />
                    </div>
                    <Link to={`/notes/${note.id}`} className="text-gray-300 hover:text-gray-100 transition-colors flex-grow">
                      {note.title}
                    </Link>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-black-700 text-gray-100 p-4">
      <div className="max-w-7xl mx-auto">
        <header className="flex justify-between items-center mb-10">
          <h1 className="text-4xl font-bold text-gray-300">
            Cybersecurity Notes
          </h1>
          <nav>
            <Link to="/" className="btn mr-2 bg-gray-800 hover:bg-gray-700 text-gray-200 px-4 py-2 rounded transition-colors">Back to Home</Link>
            {isAuthenticated ? (
              <>
                <button onClick={handleAddNote} className="btn mr-2 bg-green-600 hover:bg-green-500 text-white px-4 py-2 rounded transition-colors">Add New Note</button>
                <button onClick={handleLogout} className="btn bg-red-600 hover:bg-red-500 text-white px-4 py-2 rounded transition-colors">Logout</button>
              </>
            ) : (
              <button onClick={() => setShowLoginForm(true)} className="btn bg-gray-600 hover:bg-gray-500 text-white px-4 py-2 rounded transition-colors">Login</button>
            )}
          </nav>
        </header>

        {showLoginForm && !isAuthenticated && (
          <Login onLogin={handleLogin} />
        )}

        {isAuthenticated && (
          <div className="mb-10 flex items-center">
            {showNewFolderInput ? (
              <form onSubmit={createFolder} className="flex items-center">
                <input
                  type="text"
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                  placeholder="New folder name"
                  className="mr-2 px-3 py-2 bg-gray-700 text-white rounded"
                />
                <button type="submit" className="btn bg-blue-600 hover:bg-blue-500 text-white px-4 py-2 rounded transition-colors mr-2">
                  Create
                </button>
                <button onClick={() => setShowNewFolderInput(false)} className="btn bg-gray-600 hover:bg-gray-500 text-white px-4 py-2 rounded transition-colors">
                  Cancel
                </button>
              </form>
            ) : (
              <button 
                onClick={() => setShowNewFolderInput(true)} 
                className="btn bg-blue-600 hover:bg-blue-500 text-white px-4 py-2 rounded transition-colors flex items-center"
              >
                <PlusCircle className="mr-2" size={20} />
                New Folder
              </button>
            )}
          </div>
        )}

<DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="folders" type="folder" direction="horizontal" isDropDisabled={!isAuthenticated}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 transition-colors duration-200 ease-in-out ${
                  snapshot.isDraggingOver ? 'bg-gray-750' : ''
                }`}
              >
                {folders.map((folder, index) => (
                  <Draggable key={folder.id} draggableId={folder.id.toString()} index={index} isDragDisabled={!isAuthenticated}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`bg-gray-800 p-6 rounded-lg shadow-md border border-gray-700 transition-all duration-200 ease-in-out ${
                          snapshot.isDragging ? 'shadow-lg ring-2 ring-blue-500 scale-105' : 'hover:shadow-lg'
                        } ${!isAuthenticated ? 'cursor-default' : ''}`}
                        style={{
                          ...provided.draggableProps.style,
                          transform: snapshot.isDragging ? `${provided.draggableProps.style.transform} scale(1.05)` : provided.draggableProps.style.transform,
                        }}
                      >
                        <div className="flex justify-between items-center mb-4">
                          <h3 className="text-xl font-semibold text-gray-200 flex items-center">
                            <Folder className="mr-2" size={20} />
                            {folder.name}
                          </h3>
                          <div className="flex items-center">
                            {folder.id !== 'uncategorized' && isAuthenticated && (
                              <button onClick={() => deleteFolder(folder.id)} className="text-red-400 hover:text-red-300 transition-colors mr-2" title="Delete folder">
                                <X size={20} />
                              </button>
                            )}
                            <div {...provided.dragHandleProps} className="cursor-grab active:cursor-grabbing" title="Drag to reorder folder">
                              <GripVertical size={20} className="text-gray-400 hover:text-gray-200 transition-colors" />
                            </div>
                          </div>
                        </div>
                        {renderNotes(notes.filter(note => note.folder_id === folder.id), folder.id)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <div className="bg-gray-800 p-6 rounded-lg shadow-md border border-gray-700">
                  <h3 className="text-xl font-semibold text-gray-200 flex items-center mb-4">
                    <Folder className="mr-2" size={20} />
                    Uncategorized Notes
                  </h3>
                  {renderNotes(notes.filter(note => note.folder_id === null), 'uncategorized')}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

export default Notes;