import React, { useState, useEffect } from 'react';

function Webport() {
  const [webportData, setWebportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/api/webport')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setWebportData(data);
        setLoading(false);
      })
      .catch(error => {
        setError('Error fetching webport data: ' + error.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <div className="text-center mt-20">Loading webport data...</div>;
  if (error) return <div className="text-center mt-20 text-red-500">{error}</div>;

  return (
    <div className="container mx-auto mt-20 p-4">
      <h1 className="text-3xl font-bold mb-4">Webport</h1>
      {webportData && (
        <div className="bg-gray-800 p-4 rounded">
          <h2 className="text-xl font-semibold">{webportData.title}</h2>
          <p>{webportData.description}</p>
          {/* Add more fields as needed */}
        </div>
      )}
    </div>
  );
}

export default Webport