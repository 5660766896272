import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Mail, Twitter, Github, Linkedin } from 'lucide-react';

function Footer() {
  const currentYear = new Date().getFullYear();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [sliderStyles, setSliderStyles] = useState({ width: 0, left: 0, opacity: 0 });
  const navRef = useRef(null);

  const quickLinks = ['ABOUT', 'NOTES', 'PROJECTS', 'CLI', 'COOKING', 'SETUP', 'REMINDER'];

  useEffect(() => {
    if (navRef.current) {
      const firstButton = navRef.current.children[0];
      setSliderStyles({
        width: firstButton.offsetWidth,
        left: firstButton.offsetLeft,
        opacity: 0,
      });
    }
  }, []);

  useEffect(() => {
    if (navRef.current && hoveredIndex !== null) {
      const button = navRef.current.children[hoveredIndex];
      setSliderStyles({
        width: button.offsetWidth,
        left: button.offsetLeft,
        opacity: 1,
      });
    } else if (hoveredIndex === null) {
      setSliderStyles(prev => ({ ...prev, opacity: 0 }));
    }
  }, [hoveredIndex]);

  return (
    <footer className="bg-black text-white py-12 relative overflow-hidden">
      {/* Gradient dot background */}
      <div 
        className="absolute inset-0 opacity-[0.03]"
        style={{
          backgroundImage: `
            radial-gradient(#ffffff 1px, transparent 1px), 
            radial-gradient(#ffffff 1px, transparent 1px)
          `,
          backgroundSize: '50px 50px',
          backgroundPosition: '0 0, 25px 25px',
          maskImage: 'linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.2) 100%)',
          WebkitMaskImage: 'linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.2) 100%)',
        }}
      ></div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          {/* Contact Information */}
          <div className="mb-4 md:mb-0">
            <h3 className="text-xl font-bold mb-2 text-purple-400">Contact</h3>
            <p className="flex items-center">
              <Mail className="mr-2" size={18} />
              <a href="mailto:haidenveno@gmail.com" className="hover:text-purple-400 transition-colors">
                haidenveno@gmail.com
              </a>
            </p>
            <p className="flex items-center mt-1">
              <Twitter className="mr-2" size={18} />
              <a href="https://twitter.com/haidenveno" target="_blank" rel="noopener noreferrer" className="hover:text-purple-400 transition-colors">
                @HaidenVeno
              </a>
            </p>
          </div>

          {/* Quick Links */}
          <div className="mb-4 md:mb-0">
            <h3 className="text-xl font-bold mb-2 text-purple-400 text-center">Quick Links</h3>
            <nav ref={navRef} className="flex justify-center items-center relative">
              {quickLinks.map((item, index) => (
                <button
                  key={item}
                  className={`
                    px-2 py-1 text-xs font-medium relative z-10
                    transition-colors duration-300
                    ${index === hoveredIndex ? 'text-white' : 'text-gray-400'}
                    hover:text-gray-200
                  `}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  {item}
                </button>
              ))}
              <motion.div
                className="absolute h-6 bg-gray-700 rounded-full bottom-0.5"
                initial={false}
                animate={sliderStyles}
                transition={{
                  type: 'spring',
                  stiffness: 500,
                  damping: 30,
                  opacity: { duration: 0.2 }
                }}
              />
            </nav>
          </div>

          {/* Social Media */}
          <div>
            <h3 className="text-xl font-bold mb-2 text-purple-400">Connect</h3>
            <div className="flex justify-center space-x-4">
              <motion.a
                href="https://github.com/haidenveno"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Github className="text-white hover:text-purple-400 transition-colors" size={24} />
              </motion.a>
              <motion.a
                href="https://linkedin.com/in/haidenveno"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Linkedin className="text-white hover:text-purple-400 transition-colors" size={24} />
              </motion.a>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-8 pt-8 border-t border-gray-800 text-center">
          <p className="text-sm text-gray-500">
            © {currentYear} Haiden Veno. All rights reserved.
          </p>
          <p className="text-sm text-gray-600 mt-2">
            Aspiring Cybersecurity Professional
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;