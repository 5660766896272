import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledContent = styled.div`
  background-color: #2a2a2a;
  color: #ff3333;
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 20px auto;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 51, 51, 0.3);

  h1 {
    color: #ff3333;
    border-bottom: 2px solid #ff3333;
    padding-bottom: 10px;
    margin-top: 0;
  }

  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    background-color: #333;
    padding: 15px;
    border-radius: 5px;
    font-size: 14px;
  }

  textarea {
    background-color: #333;
    color: #ff3333;
    border: 1px solid #ff3333;
    width: 100%;
    height: 400px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 5px;
  }

  button {
    background-color: #ff3333;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    font-family: Arial, sans-serif;
    font-weight: bold;
    border-radius: 5px;
    margin-right: 10px;

    &:hover {
      background-color: #cc0000;
    }
  }

  .back-button {
    background-color: #ff3333;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    margin-bottom: 20px;
    display: inline-block;
    border-radius: 5px;
    font-weight: bold;

    &:hover {
      background-color: #cc0000;
    }
  }

  .delete-button {
    background-color: #ff0000;

    &:hover {
      background-color: #cc0000;
    }
  }
`;


function NoteView() {
  const [note, setNote] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { noteId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNote = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`/api/notes/${noteId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setNote(data.note);
        setEditedContent(data.note.content);
        setIsAuthenticated(data.is_authenticated);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching note:', error);
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchNote();
  }, [noteId]);


  const enableEdit = () => setIsEditing(true);

  const cancelEdit = () => {
    setIsEditing(false);
    setEditedContent(note.content);
  };

  const saveContent = async () => {
    try {
      const response = await fetch(`/api/edit/${noteId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content: editedContent })
      });
      const data = await response.json();
      if (response.ok) {
        setNote({ ...note, content: editedContent });
        setIsEditing(false);
      } else {
        setError(data.error);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while saving the note');
    }
  };

  const deleteNote = async () => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      try {
        const response = await fetch(`/api/delete_note/${noteId}`, {
          method: 'POST',
        });
        const data = await response.json();
        if (data.success) {
          navigate('/notes');
        } else {
          setError(data.error);
        }
      } catch (error) {
        console.error('Error:', error);
        setError('An error occurred while deleting the note');
      }
    }
  };

  if (isLoading) return <StyledContent>Loading...</StyledContent>;
  if (error) return <StyledContent>Error: {error}</StyledContent>;
  if (!note) return <StyledContent>No note found</StyledContent>;

  return (
    <StyledContent>
      <Link to="/notes" className="back-button">Back to Notes</Link>
      <h1>{note.title}</h1>
      {!isEditing ? (
        <pre dangerouslySetInnerHTML={{ __html: note.content }} />
      ) : (
        <textarea
          value={editedContent}
          onChange={(e) => setEditedContent(e.target.value)}
          rows="20"
        />
      )}
      {isAuthenticated && (
        <>
          {!isEditing ? (
            <>
              <button onClick={enableEdit}>Edit</button>
              <button className="delete-button" onClick={deleteNote}>Delete</button>
            </>
          ) : (
            <>
              <button onClick={saveContent}>Save Changes</button>
              <button onClick={cancelEdit}>Cancel</button>
            </>
          )}
        </>
      )}
    </StyledContent>
  );
}

export default NoteView;