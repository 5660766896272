import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState({});
  const [selectedDay, setSelectedDay] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [error, setError] = useState(null);  // Added error state

  const fetchEvents = useCallback(async () => {
    try {
      const response = await axios.get('/api/events', {
        params: {
          year: currentDate.getFullYear(),
          month: currentDate.getMonth() + 1
        }
      });
      setEvents(response.data);
      setError(null);  // Clear any previous errors
    } catch (error) {
      console.error('Error fetching events:', error);
      setError('Failed to fetch events. Please try again later.');
    }
  }, [currentDate]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const openDayDialog = (day) => {
    setSelectedDay(day);
    setIsDialogOpen(true);
  };

  const closeDayDialog = () => {
    setIsDialogOpen(false);
    setSelectedDay(null);
  };

  const addEvent = async (day) => {
    const eventName = prompt('Enter event name:');
    if (eventName) {
      const dateKey = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      try {
        const response = await axios.post('/api/events', {
          title: eventName,
          date: dateKey
        });
        console.log('Event created:', response.data);
        fetchEvents();
        setError(null);  // Clear any previous errors
      } catch (error) {
        console.error('Error adding event:', error.response?.data || error.message);
        setError(`Failed to add event: ${error.response?.data?.error || error.message}`);
      }
    }
  };

  const deleteEvent = async (eventId) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      try {
        await axios.delete(`/api/events/${eventId}`);
        fetchEvents();
        setError(null);  // Clear any previous errors
      } catch (error) {
        console.error('Error deleting event:', error);
        setError('Failed to delete event. Please try again later.');
      }
    }
  };

  const renderCalendarDays = useCallback(() => {
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const days = [];
    const today = new Date();

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const dateKey = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      const isToday = today.getDate() === day && 
                      today.getMonth() === currentDate.getMonth() && 
                      today.getFullYear() === currentDate.getFullYear();

      days.push(
        <div 
          key={day} 
          className={`calendar-day ${isToday ? 'today' : ''}`}
          onClick={() => openDayDialog(day)}
        >
          <div className="day-number">{day}</div>
          <ul className="event-list">
            {events[dateKey]?.slice(0, 2).map((event) => (
              <li key={event.id} className="event-item">{event.title}</li>
            ))}
            {events[dateKey]?.length > 2 && (
              <li className="more-events">+{events[dateKey].length - 2} more</li>
            )}
          </ul>
        </div>
      );
    }

    return days;
  }, [currentDate, events]);

  return (
    <div className="calendar">
      <div className="calendar-header">
        <button onClick={prevMonth} className="nav-button">&lt; Prev</button>
        <h2>{currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</h2>
        <button onClick={nextMonth} className="nav-button">Next &gt;</button>
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className="weekdays">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="weekday">{day}</div>
        ))}
      </div>
      <div className="calendar-grid">
        {renderCalendarDays()}
      </div>
      {isDialogOpen && (
        <div className="dialog-overlay" onClick={closeDayDialog}>
          <div className="dialog" onClick={e => e.stopPropagation()}>
            <h3>Events for {selectedDay} {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</h3>
            <ul className="dialog-event-list">
              {events[`${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDay?.toString().padStart(2, '0')}`]?.map((event) => (
                <li key={event.id} className="dialog-event-item">
                  <span>{event.title}</span>
                  <button onClick={() => deleteEvent(event.id)} className="delete-button">X</button>
                </li>
              ))}
            </ul>
            <button onClick={() => addEvent(selectedDay)} className="add-event-button">Add Event</button>
            <button onClick={closeDayDialog} className="close-button">Close</button>
          </div>
        </div>
      )}
      <style jsx>{`
        .calendar {
          background-color: #1E1E1E;
          color: #e5e9e5;
          border: 2px solid #ff6600;
          border-radius: 10px;
          padding: 20px;
          font-family: 'Hack', monospace;
          max-width: 800px;
          margin: 20px auto;
        }
        .calendar-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }
        .nav-button {
          background-color: #ff6600;
          color: #000;
          border: none;
          padding: 5px 10px;
          cursor: pointer;
          font-family: 'Hack', monospace;
          font-weight: bold;
        }
        .nav-button:hover {
          background-color: #ff8c00;
        }
        .weekdays {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          gap: 5px;
          margin-bottom: 10px;
        }
        .weekday {
          text-align: center;
          font-weight: bold;
        }
        .calendar-grid {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          gap: 5px;
        }
        .calendar-day {
          border: 1px solid #ff6600;
          padding: 10px;
          min-height: 100px;
          position: relative;
          cursor: pointer;
        }
        .calendar-day.empty {
          background-color: rgba(255, 102, 0, 0.1);
        }
        .calendar-day.today {
          background-color: rgba(255, 102, 0, 0.2);
        }
        .day-number {
          position: absolute;
          top: 5px;
          right: 5px;
          font-weight: bold;
        }
        .event-list {
          list-style-type: none;
          padding: 0;
          margin: 20px 0 0 0;
        }
        .event-item {
          background-color: rgba(255, 102, 0, 0.1);
          margin-bottom: 5px;
          padding: 2px 5px;
          font-size: 12px;
          border-left: 3px solid #ff6600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .more-events {
          font-size: 12px;
          color: #ff6600;
        }
        .dialog-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .dialog {
          background-color: #1E1E1E;
          border: 2px solid #ff6600;
          border-radius: 10px;
          padding: 20px;
          max-width: 400px;
          width: 100%;
        }
        .dialog-event-list {
          list-style-type: none;
          padding: 0;
        }
        .dialog-event-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
        }
        .delete-button, .add-event-button, .close-button {
          background-color: #ff6600;
          color: #000;
          border: none;
          padding: 5px 10px;
          cursor: pointer;
          font-family: 'Hack', monospace;
          font-weight: bold;
          margin-left: 10px;
        }
        .delete-button:hover, .add-event-button:hover, .close-button:hover {
          background-color: #ff8c00;
        }
        .error-message {
          background-color: #ff6600;
          color: #1E1E1E;
          padding: 10px;
          margin-top: 10px;
          border-radius: 5px;
          font-weight: bold;
        }
      `}</style>
    </div>
  );
};

export default Calendar;