import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import styled, { createGlobalStyle, keyframes } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #000;
    color: #e5e9e5;
    margin: 0;
    font-family: 'Hack', monospace;
    font-size: 16px;
    overflow: hidden;
  }
`;

const fireGlow = keyframes`
  0% { box-shadow: 0 0 3px #ff6600, 0 0 6px #ff6600, 0 0 9px #ff6600, 0 0 12px #ff6600; }
  50% { box-shadow: 0 0 6px #ff8c00, 0 0 12px #ff8c00, 0 0 18px #ff8c00, 0 0 24px #ff8c00; }
  100% { box-shadow: 0 0 3px #ff6600, 0 0 6px #ff6600, 0 0 9px #ff6600, 0 0 12px #ff6600; }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
`;

const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;

const TerminalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const TerminalWindow = styled.div`
  width: 100%;
  max-width: 1200px;
  height: calc(100vh - 40px);
  max-height: 800px;
  border: 2px solid #ff6600;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Hack', monospace;
  font-size: 16px;
  background-color: #1E1E1E;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: ${fireGlow} 3s infinite alternate;
`;

const TerminalContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 10px;
`;

const AsciiArtContainer = styled.div`
  font-family: monospace;
  font-size: 14px;
  line-height: 1;
  white-space: pre;
  margin-bottom: 16px;
`;

const AsciiLine = styled.div`
  color: ${props => props.color};
`;

const Info = styled.div`
  padding-left: 450px;
  padding-bottom: 10px;
`;

const PromptLine = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  word-break: break-all;
`;


const PromptUser = styled.span`
  color: #CB6407;
`;

const PromptAt = styled.span`
  color: #CD5C5C;
`;

const PromptHost = styled.span`
  color: #DAA520;
`;

const PromptPath = styled.span`
  color: #B8860B;
`;

const UserInput = styled.input`
  background: none;
  border: none;
  outline: none;
  color: #e5e9e5;
  font-family: inherit;
  font-size: 16px;
  margin: 0;
  padding: 0;
  width: 100%;
  flex: 1;
`;
const CommandOutput = styled.div`
  white-space: pre-wrap;
  word-break: break-all;
`;

const Prompt = () => (
    <span>
      <PromptUser>guest</PromptUser>
      <PromptAt>@</PromptAt>
      <PromptHost>hveno</PromptHost>:
      <PromptPath>~</PromptPath>$&nbsp;
    </span>
  );
const AsciiArt = () => {
  const asciiLines = [
    { color: '#4E1118', content: '██╗  ██╗ █████╗ ██╗██████╗ ███████╗███╗   ██╗    ██╗   ██╗███████╗███╗   ██╗ ██████╗ ' },
    { color: '#651A21', content: '██║  ██║██╔══██╗██║██╔══██╗██╔════╝████╗  ██║    ██║   ██║██╔════╝████╗  ██║██╔═══██╗' },
    { color: '#7C232A', content: '███████║███████║██║██║  ██║█████╗  ██╔██╗ ██║    ██║   ██║█████╗  ██╔██╗ ██║██║   ██║' },
    { color: '#932C33', content: '██╔══██║██╔══██║██║██║  ██║██╔══╝  ██║╚██╗██║    ╚██╗ ██╔╝██╔══╝  ██║╚██╗██║██║   ██║' },
    { color: '#AA353C', content: '██║  ██║██║  ██║██║██████╔╝███████╗██║ ╚████║     ╚████╔╝ ███████╗██║ ╚████║╚██████╔╝' },
    { color: '#C13E45', content: '╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚═════╝ ╚══════╝╚═╝  ╚═══╝      ╚═══╝  ╚══════╝╚═╝  ╚═══╝ ╚═════╝ ' },
  ];

  return (
    <>
      <PromptLine>
        <Prompt />
      </PromptLine>
      <AsciiArtContainer>
        {asciiLines.map((line, index) => (
          <AsciiLine key={index} color={line.color}>
            {line.content}
          </AsciiLine>
        ))}
      </AsciiArtContainer>
    </>
  );
};

const Terminal = () => {
  const [terminalContent, setTerminalContent] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [commandHistory, setCommandHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const terminalRef = useRef(null);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const initialContent = useMemo(() => [
    { type: 'ascii', content: <AsciiArt /> },
    { type: 'info', content: 'CyberSecurity Major - Student - Programmer' },
    { type: 'text', content: "Type 'webport' to view my cybersecurity portfolio" },
    { type: 'text', content: "Type 'notes' to view my university notes" },
    { type: 'text', content: "Type 'ls' to view available built-in write ups" },
    { type: 'text', content: "Type 'about' for more information about me" },
    { type: 'text', content: "Type 'writeups' to see my projects/writeups" },
    { type: 'text', content: "Type 'help' for list of all commands" },
    { type: 'text', content: "Type 'clear' to clear the screen" },
    { type: 'prompt', content: '' }
  ], []);

  useEffect(() => {
    setTerminalContent(initialContent);
    if (inputRef.current) {
      inputRef.current.focus();
    }
    
    // Reset scroll position when the component mounts or the route changes
    window.scrollTo(0, 0);
  }, [location.pathname, initialContent]);

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [terminalContent]);
const handleInputChange = (e) => {
  setInputValue(e.target.value);
};

const handleInputKeyDown = (e) => {
  if (e.key === 'Enter' && !e.shiftKey) {
    e.preventDefault();
    const command = inputValue.trim();
    let newContent = [...terminalContent];

    // Update the last prompt with the entered command
    newContent[newContent.length - 1].content = command;

    if (command !== '') {
      setCommandHistory([command, ...commandHistory]);
      setHistoryIndex(-1);
      let output = '';
      if (commands[command]) {
        output = commands[command]();
      } else {
        output = `command not found: ${command}`;
      }
      if (command !== 'clear') {
        newContent.push({ type: 'output', content: output });
      } else {
        newContent = [];
      }
    }

    // Add a new prompt line
    newContent.push({ type: 'prompt', content: '' });

    setTerminalContent(newContent);
    setInputValue('');
  } else if (e.key === 'ArrowUp') {
    e.preventDefault();
    if (historyIndex < commandHistory.length - 1) {
      const newIndex = historyIndex + 1;
      setHistoryIndex(newIndex);
      setInputValue(commandHistory[newIndex]);
    }
  } else if (e.key === 'ArrowDown') {
    e.preventDefault();
    if (historyIndex > 0) {
      const newIndex = historyIndex - 1;
      setHistoryIndex(newIndex);
      setInputValue(commandHistory[newIndex]);
    } else if (historyIndex === 0) {
      setHistoryIndex(-1);
      setInputValue('');
    }
  }
};

const commands = {
  webport: () => {
    navigate('/webport');
    return "Redirecting to Cybersecurity Portfolio...";
  },
  notes: () => {
    navigate('/notes');
    return "Redirecting to Notes";
  },
  writeups: () => {
    navigate('/pdfs');
    return "Redirecting to writeups";
  },
  help: () => `The following commands are enabled:

  about banner bing cat crackmes date discord
  email github google help htb instagram linkedin ls quote readme
  reddit repo resume whoami writeups webport

  [tab]: trigger completion.
  [ctrl+l]/clear: clear terminal.`,
  whoami: () => 'guest',
  clear: () => {
    setTerminalContent([{ type: 'prompt', content: '' }]);
    return '';
  },
  login: () => "shell: command not found: login. Try 'help' to get started."
};

return (
  <PageWrapper>
    <GlobalStyle />
    <MainContent>
      <TerminalContainer>
        <TerminalWindow>
          <TerminalContent ref={terminalRef}>
            {terminalContent.map((line, index) => {
              switch (line.type) {
                case 'ascii':
                  return <React.Fragment key={index}>{line.content}</React.Fragment>;
                case 'info':
                  return <Info key={index}>{line.content}</Info>;
                case 'text':
                  return <div key={index}>{line.content}</div>;
                case 'prompt':
                  return (
                    <PromptLine key={index}>
                      <Prompt />
                      {index === terminalContent.length - 1 ? (
                        <UserInput
                          ref={inputRef}
                          value={inputValue}
                          onChange={handleInputChange}
                          onKeyDown={handleInputKeyDown}
                        />
                      ) : (
                        <span>{line.content}</span>
                      )}
                    </PromptLine>
                  );
                case 'command':
                  return (
                    <PromptLine key={index}>
                      <Prompt />
                      <span>{line.content}</span>
                    </PromptLine>
                  );
                case 'output':
                  return <CommandOutput key={index}>{line.content}</CommandOutput>;
                default:
                  return null;
              }
            })}
          </TerminalContent>
        </TerminalWindow>
      </TerminalContainer>
    </MainContent>
  </PageWrapper>
);
}

export default Terminal;