import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const LoginContainer = styled.div`
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.3);
  color: #00ff00;
  font-family: 'Courier New', monospace;
`;

const Title = styled.h1`
  color: #00ff00;
  border-bottom: 2px solid #00ff00;
  padding-bottom: 10px;
  margin-top: 0;
`;

const Input = styled.input`
  background-color: rgba(0, 0, 0, 0.8);
  color: #00ff00;
  border: 1px solid #00ff00;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-family: 'Courier New', monospace;
  font-size: 14px;
  box-sizing: border-box;
`;

const Button = styled.button`
  background-color: #00ff00;
  color: #000000;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Courier New', monospace;
  font-weight: bold;
  width: 100%;

  &:hover {
    background-color: #00cc00;
  }
`;

const CaptchaContainer = styled.div`
  margin-bottom: 10px;
  color: #00ff00;
`;

const Message = styled.div`
  margin-top: 10px;
  color: ${props => props.error ? '#ff0000' : '#00ff00'};
`;

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [captchaAnswer, setCaptchaAnswer] = useState('');
  const [captchaQuestion, setCaptchaQuestion] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    try {
      const response = await fetch('/api/captcha');
      const data = await response.json();
      setCaptchaQuestion(data.question);
    } catch (error) {
      console.error('Error fetching captcha:', error);
      setMessage('Error loading captcha');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
          captcha: captchaAnswer,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        onLogin(true);
        setMessage(data.message);
      } else {
        setMessage(data.error);
        fetchCaptcha(); // Refresh captcha on failed attempt
      }
    } catch (error) {
      console.error('Login error:', error);
      setMessage('An error occurred during login');
    }
  };

  return (
    <LoginContainer>
      <Title>Login</Title>
      <form onSubmit={handleSubmit}>
        <Input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
        />
        <Input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <CaptchaContainer>
          <label htmlFor="captcha">Solve: {captchaQuestion} = </label>
          <Input
            type="text"
            id="captcha"
            value={captchaAnswer}
            onChange={(e) => setCaptchaAnswer(e.target.value)}
            required
          />
        </CaptchaContainer>
        <Button type="submit">Login</Button>
      </form>
      {message && <Message error={message.includes('Error') || message.includes('Invalid')}>{message}</Message>}
    </LoginContainer>
  );
}

export default Login;