import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const EditNoteContainer = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #1a1a1a;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.3);
  color: #00ff00;
  font-family: 'Courier New', monospace;
`;

const Title = styled.h1`
  color: #00ff00;
  border-bottom: 2px solid #00ff00;
  padding-bottom: 10px;
  margin-top: 0;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #2a2a2a;
  border: 1px solid #00ff00;
  color: #00ff00;
  font-family: 'Courier New', monospace;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 300px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #2a2a2a;
  border: 1px solid #00ff00;
  color: #00ff00;
  font-family: 'Courier New', monospace;
  resize: vertical;
`;

const Button = styled.button`
  background-color: #00ff00;
  color: #000;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    background-color: #00cc00;
  }
`;

function EditNote() {
  const [note, setNote] = useState({ title: '', content: '' });
  const { noteId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNote = async () => {
      try {
        const response = await fetch(`/api/notes/${noteId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch note');
        }
        const data = await response.json();
        setNote(data.note);
      } catch (error) {
        console.error('Error fetching note:', error);
      }
    };

    fetchNote();
  }, [noteId]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNote({ ...note, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/api/edit_note/${noteId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(note),
      });
      if (!response.ok) {
        throw new Error('Failed to update note');
      }
      navigate('/notes');
    } catch (error) {
      console.error('Error updating note:', error);
    }
  };

  return (
    <EditNoteContainer>
      <Title>Edit Note</Title>
      <form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="title"
          value={note.title}
          onChange={handleInputChange}
          placeholder="Note Title"
          required
        />
        <TextArea
          name="content"
          value={note.content}
          onChange={handleInputChange}
          placeholder="Note Content"
          required
        />
        <Button type="submit">Save Changes</Button>
        <Button type="button" onClick={() => navigate('/notes')}>Cancel</Button>
      </form>
    </EditNoteContainer>
  );
}

export default EditNote;