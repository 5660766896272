import React, { useState, useEffect } from 'react';
import { ScrollAnimation } from './ScrollAnimation';

function About() {
  const [text, setText] = useState('');
  const fullText = "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus";
 
  useEffect(() => {
    let i = 0;
    const typingEffect = setInterval(() => {
      if (i < fullText.length) {
        setText(prevText => prevText + fullText.charAt(i));
        i++;
      } else {
        clearInterval(typingEffect);
      }
    }, 50);
    return () => clearInterval(typingEffect);
  }, []);

  return (
    <section className="py-10 bg-black relative overflow-hidden">
      {/* Gradient dot background */}
      <div
        className="absolute inset-0"
        style={{
          backgroundImage: `radial-gradient(#ffffff 1px, transparent 1px), radial-gradient(#ffffff 1px, transparent 1px)`,
          backgroundSize: '50px 50px',
          backgroundPosition: '0 0, 25px 25px',
          opacity: 0.15,
          maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9) 80%, rgba(0,0,0,0.8) 100%)',
          WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9) 80%, rgba(0,0,0,0.8) 100%)',
        }}
      ></div>

      {/* Existing content */}
      <ScrollAnimation>
        <h2 className="text-center text-2xl font-bold mb-6 text-gray-300 relative z-10">EXPLORE ABOUT ME</h2>
      </ScrollAnimation>
      <ScrollAnimation>
        <div className="flex flex-col items-center mb-4 relative z-10">
          <div className="w-px h-12 bg-gradient-to-t from-blue-500 to-transparent"></div>
          <div className="w-8 h-8 rounded-full bg-gradient-to-br from-blue-500 to-white flex items-center justify-center text-blue-900 font-bold">
            <span>1</span>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation>
        <h3 className="text-center text-3xl font-bold mb-4 text-blue-500 relative z-10">
          About
        </h3>
      </ScrollAnimation>
      <ScrollAnimation>
        <p className="text-center text-xl text-gray-300 max-w-3xl mx-auto relative z-10">
          {text}
        </p>
      </ScrollAnimation>
    </section>
  );
}

export default About;